<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="webIcon" @click="toWeb"></div>
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
      <div class="business" @click="toBusiness"></div>
    </div>
    <div class="figure"></div>
    <div class="logoText"></div>
    <div class="bottomTip"></div>
    <div class="downloadBox">
      <img class="qrcodeBox" :src="qrImg" alt="" />
      <div class="btnBox">
        <div class="iosBtn"></div>
        <div class="androidBtn"></div>
        <div class="tip"></div>
      </div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toWeb() {
      window.open("https://emcmqw.com");
    },
    toTg() {
      window.open("https://t.me/tik99cc");
    },
    toPotato() {
      window.open("https://chptdl.org/douyincr");
    },
    toBusiness() {
      window.open("https://t.me/ShangWuhXiao2Ge");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    height: 80px;
    width: 309px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 44px;
    left: 152px;
  }
  .contactBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 61px;
    top: 49px;
    .webIcon {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/webIcon.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      margin-right: 30px;
    }
    .business {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/business.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figure {
    height: 896px;
    width: 818px;
    background: url("./../../../assets/images/pc/people.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 152px;
    bottom: 0;
  }
  .logoText {
    height: 242px;
    width: 771px;
    background: url("./../../../assets/images/pc/logoText.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 159px;
    top: 312.2px;
  }
  .bottomTip {
    height: 45px;
    width: 630px;
    background: url("./../../../assets/images/pc/bottomTip.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 275px;
    bottom: 79px;
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 327px;
    bottom: 187px;
    .qrcodeBox {
      height: 211px;
      width: 209.7px;
      background-color: white;
      border-radius: 10px;
      margin-right: 44.3px;
    }
    .btnBox {
      .iosBtn {
        height: 68px;
        width: 324px;
        background: url("./../../../assets/images/pc/iosBtn.png") no-repeat;
        background-size: 100% 100%;
      }
      .androidBtn {
        height: 68px;
        width: 324px;
        background: url("./../../../assets/images/pc/androidBtn.png") no-repeat;
        background-size: 100% 100%;
        margin: 24px 0;
      }
      .tip {
        height: 20px;
        width: 250px;
        background: url("./../../../assets/images/pc/tips.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
